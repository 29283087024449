// 
// user-variables.scss
// Use this to overwrite Bootstrap and Geeks variables
//
// Example of a variable override to change Geeks primary color
// Remove the "//" to comment it in and see it in action!
// $primary: #000;
$primary: #2c80c0;


