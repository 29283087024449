.modalContent {
  color: #fff;
  background: linear-gradient(
    0deg,
    rgba(0, 82, 239, 1) 0%,
    rgba(189, 234, 250, 1) 100%
  );
}

.content {
  font-size: 18px;
}

.button {
  background-color: #f4aa3b !important;
  border-color: #f4aa3b !important;
}

.button:hover {
  background-color: #ffc164 !important;
  border-color: #ffc164 !important;
}
