// youtube-video modal

.close-button {
  position: absolute;
  top: -45px;
  right: -50px;
}

.youtube-iframe {
  position: relative;
  width: 100%;
  height: 500px;
  border: 0;
}
