/* Theme: Geeks */

// Material icons
@import "node_modules/@mdi/font/scss/materialdesignicons";
@import "./vendor/materialdesignicons.min";
// Font Awesome
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.11.2/css/all.css");
// Feather
@import "./vendor/feather";
// Font Awesome
@import "node_modules/@fortawesome/fontawesome-free/css/all.min";

// Material Icons
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");

// Bootstrap functions
@import "node_modules/bootstrap/scss/functions";

// User Variables
@import "user-variables";

// theme variables
@import "theme/variables";

//utilities
@import "theme/utilities";

// Bootstrap
@import "bootstrap/scss/bootstrap";

// Root
@import "theme/root";

// Theme
@import "theme/theme";

@import "node_modules/slick-carousel/slick/slick";
@import "node_modules/slick-carousel/slick/slick-theme";
@import "node_modules/@material/react-snackbar/dist/snackbar";

// User
@import "user";
