//
// user.scss
// Use this to write your custom SCSS
//

@import "./components/_youtube-video-modal";

@import "./components/shops";
@import "./components/_testimonials";

.table .td .btn-link {
  padding: 0;
}

.table td {
  vertical-align: middle;
}

// Tiny slider

.controls {
  margin: 0;
  padding: 0;
  list-style: none;
  position: absolute;
  left: 0;
  font-size: 18px;
  width: 100%;
  top: 50%;
  transform: translatey(-50%);
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 768px) {
    position: absolute;
    bottom: -50px;
    justify-content: center;
    top: inherit;
  }
  &:focus {
    outline: none;
  }
  li {
    cursor: pointer;
    &.prev {
      position: relative;
      background-color: $white;
      height: 40px;
      width: 40px;
      margin-left: -50px;
      border-radius: 50%;
      line-height: 40px;
      text-align: center;
      border: 1px solid $gray-200;
      transition: 0.2s ease-in;
      color: $gray-400;
      @media (max-width: 768px) {
        margin-right: 5px;
      }
      &:hover {
        background-color: $primary;
        color: $white;
        border-color: $primary;
      }
    }
    &.next {
      position: relative;
      background-color: $white;
      height: 40px;
      width: 40px;
      margin-right: -50px;
      border-radius: 50%;
      line-height: 40px;
      text-align: center;
      border: 1px solid $gray-200;
      transition: 0.2s ease-in;
      color: $gray-400;
      @media (max-width: 768px) {
        margin-left: 5px;
      }
      &:hover {
        background-color: $primary;
        color: $white;
        border-color: $primary;
      }
    }
  }
}
.controls-testimonial {
  position: absolute;
  bottom: -100px;
  justify-content: center;
  top: inherit;

  .prev,
  .next {
    margin: 0 10px;
  }
}

.dropzone {
  border-radius: 10px;
  border-width: 1px !important;
  border-color: rgba($primary, 0.5) !important;
  background-color: rgba($primary, 0.04) !important;
  position: relative;
  min-height: 135px;
}

.shop-status {
  &.active {
    background-color: $success;
  }
  &.inactive,
  &.banned {
    background-color: $danger;
  }
  top: 0;
  width: 10px;
  position: absolute;
  left: 0;
  bottom: 0;
  border-radius: var(--geeks-card-border-radius) 0 0
    var(--geeks-card-border-radius);
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.overflow-hidden {
  overflow: hidden;
}

.navbar-nav-brand,
.navbar-nav-brand-img {
  width: 200px;

  @media (max-width: 576px) {
    width: 160px !important;
  }
}

.flush-nav {
  .nav {
    .nav-link {
      @media (max-width: 768px) {
        padding: 1rem 0.5rem;
      }
    }
  }
}

.partner-img {
  width: 75%;
}
